<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">
          <h2 class="text-xl font-medium mr-auto border-b pb-5">
            <app-i18n code="slider.edit.title" v-if="isEditing"></app-i18n>
            <app-i18n code="slider.new.title" v-else></app-i18n>
          </h2>
          <section class="relative">
            <div
              class="flex justify-center"
              style="width: 100%; height: 80vh"
              v-if="findLoading"
            >
              <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
            </div>
            <form v-if="model">
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('slider.fields.title') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input
                    id="title-en"
                    type="text"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.title.en"
                    :placeholder="i18n('slider.fields.english')"
                  />
                </div>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2"
                  :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'"
                >
                  <input
                    id="title-ar"
                    type="text"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.title.ar"
                    :placeholder="i18n('slider.fields.arabic')"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('slider.fields.description') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <textarea
                    id="description-en"
                    :rows="4"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.description.en"
                    :placeholder="i18n('slider.fields.english')"
                  ></textarea>
                </div>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2"
                  :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'"
                >
                  <textarea
                    id="description-ar"
                    :rows="4"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.description.ar"
                    :placeholder="i18n('slider.fields.arabic')"
                  ></textarea>
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('slider.fields.image') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <div class="relative" v-if="isEditing && imageEnUrl">
                    <img
                      :src="imageEnUrl"
                      alt=""
                      style="width: 100%; height: 150px"
                    />
                    <!-- <img :src="/images/sliderPlaceholder.png" alt="" style="width: 100%" /> -->
                    <img
                      src="/images/deleteIcon.png"
                      alt=""
                      class="delete-icon w-12 h-12 absolute bottom-0 left-0 cursor-pointer"
                      @click="model.imageEn = undefined"
                    />
                  </div>
                  <Uploader
                    type="image/*"
                    :maxSize="5"
                    ref="uploaderEn"
                    v-else
                  ></Uploader>
                </div>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2"
                  :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'"
                >
                  <div class="relative" v-if="isEditing && imageArUrl">
                    <img
                      :src="imageArUrl"
                      alt=""
                      style="width: 100%; height: 150px"
                    />
                    <img
                      src="/images/deleteIcon.png"
                      alt=""
                      class="delete-icon w-12 h-12 absolute bottom-0 left-0 cursor-pointer"
                      @click="model.imageAr = undefined"
                    />
                  </div>
                  <Uploader
                    type="image/*"
                    :maxSize="5"
                    ref="uploaderAr"
                    v-else
                  ></Uploader>
                </div>
              </div>

              <div class="flex justify-center items-center mt-10">
                <AppButton
                  type="button"
                  class="btn bg-theme-36 text-white cursor-pointer"
                  :class="!saveLoading ? 'w-24' : ''"
                  :disabled="saveLoading || findLoading || uploadLoading"
                  :loading="saveLoading"
                  @click="doSubmit()"
                >
                  <strong>{{ i18n('common.save') }}</strong>
                  <template v-slot:loading>
                    <app-i18n code="common.loading"></app-i18n>
                    <LoadingIcon
                      icon="three-dots"
                      color="#FFFFFF"
                      style="margin: 0 4px"
                    />
                  </template>
                </AppButton>
                <button
                  type="button"
                  class="btn bg-theme-31 text-black w-24 ml-3 mr-3 cursor-pointer"
                  :disabled="saveLoading || findLoading || uploadLoading"
                  @click="doCancel()"
                >
                  <app-i18n code="common.cancel"></app-i18n>
                </button>
              </div>
            </form>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import Message from '@/shared/message/toastify'
import { FormSchema } from '@/shared/form/form-schema'
import { SliderModel } from '@/store/slider/slider-model'

const { fields } = SliderModel
const formSchema = new FormSchema([
  fields.id,
  fields.title,
  fields.description,
  fields.imageEn,
  fields.imageAr
])

export default defineComponent({
  props: {
    id: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      model: null,
      rules: formSchema.rules(),
      errorMessage: null
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'slider/form/record',
      findLoading: 'slider/form/findLoading',
      saveLoading: 'slider/form/saveLoading'
    }),
    isEditing() {
      return !!this.id
    },
    filesToSaveEn() {
      return this.$refs.uploaderEn?.filesToSave || []
    },
    filesToSaveAr() {
      return this.$refs.uploaderAr?.filesToSave || []
    },
    imageEnUrl() {
      return this.model && this.model.imageEn ? this.model.imageEn : null
    },
    imageArUrl() {
      return this.model && this.model.imageAr ? this.model.imageAr : null
    }
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.slider')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.slider')
    }
  },
  async mounted() {
    if (this.isEditing) {
      await this.doFind(this.id)
    } else {
      this.doNew()
    }

    this.model = formSchema.initialValues(this.record || {})
    if (this.isEditing) {
      this.model.title = this.record.title
      this.model.description = this.record.description
    }
  },
  methods: {
    ...mapActions({
      doNew: 'slider/form/doNew',
      doFind: 'slider/form/doFind',
      doCreate: 'slider/form/doCreate',
      doUpdate: 'slider/form/doUpdate'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    isFormValid() {
      if (this.model.title.en.length == 0 || this.model.title.ar.length == 0) {
        this.errorMessage = 'slider.edit.errors.emptyFields'
        return false
      }

      if (
        this.model.description.en.length == 0 ||
        this.model.description.ar.length == 0
      ) {
        this.errorMessage = 'slider.edit.errors.emptyFields'
        return false
      }

      if (this.isEditing) {
        if (!(this.imageArUrl || this.filesToSaveAr.length !== 0)) {
          this.errorMessage = 'slider.edit.errors.emptyFields'
          return false
        }
        if (!(this.imageEnUrl || this.filesToSaveEn.length !== 0)) {
          this.errorMessage = 'slider.edit.errors.emptyFields'
          return false
        }
      } else {
        if (
          this.filesToSaveEn.length === 0 ||
          this.filesToSaveAr.length === 0
        ) {
          this.errorMessage = 'slider.edit.errors.emptyFields'
          return false
        }
      }
      return true
    },
    doCancel() {
      this.$router.push('/slider')
    },
    doReset() {
      this.model = formSchema.initialValues(this.record)
    },
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        return Message.error(ERROR)
      }

      const { id, ...values } = formSchema.cast(this.model)
      if (this.isEditing) {
        await this.doUpdate({
          id,
          values,
          imageEn: this.imageEnUrl || this.filesToSaveEn[0],
          imageAr: this.imageArUrl || this.filesToSaveAr[0]
        })
      } else {
        await this.doCreate({
          values,
          imageEn: this.filesToSaveEn[0],
          imageAr: this.filesToSaveAr[0]
        })
      }
    }
  }
})
</script>
